<template>
    <div class="homepage-3">
      <ScrollupSection />
      <div class="main overflow-hidden">
        <HeaderSection />
        <BreadcrumbSection :titulo="titulo" :descripcion="descripcion" />
        <section class="section service-area">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <horizontal-stepper ref="pasosDI"
                  :steps="demoSteps"
                  @completed-step="completeStep"
                  @active-step="isStepActive"
                  @stepper-finished="alert"
                  class="m-5"
                  locale="es"
                >
                </horizontal-stepper>
              </div>
            </div>
          </div>
        </section>
        <FooterSection/>
        <ModalSearchSection />
        <ModalRegistro/>
        <ModalMenuSection />
        <ModalTerms/>
        <ModalCapcha :titulo="tituloFormato"  :nombre-archivo="tituloArchivo" :url="url" :tipo-formato="1"/>
        <Modal :show="showModal" :modalHeadMessage="modalHeadMessage" :modalMessage="modalMessage" @close="showModal = false"></Modal>
      </div>
    </div>
  
  </template>
  
  <script>
  import { analytics, auth} from "@/firebase.js";
  import { mapActions, mapMutations } from "vuex";
  import ScrollupSection from "../Scrollup/Scrollup";
  import HeaderSection from "../Header/HeaderOne";
  import BreadcrumbSection from "../Breadcrumb/Services";
  import FooterSection from "../Footer/FooterOne";
  
  import ModalSearchSection from "../Modal/ModalSearch/ModalSearch";
  import ModalRegistro from "../Modal/ModalSearch/ModalRegistro";
  import ModalMenuSection from "../Modal/ModalMenu/ModalMenu";
  import ModalTerms from '../Modal/ModalTerms/ModalTerms';
  import ModalCapcha from "@/components/Modal/ModalCaptcha/ModalCapcha";
  
  import HorizontalStepper from "vue-stepper";
  import "vue-select/dist/vue-select.css";
  //import Pruebas from "../../components/forms/VG/Pruebas";
  //import GeneralFormRepresentate from "../../components/forms/GeneralesRepresentante";
  import GeneralFormInteresado from "../../components/formsChatET/GeneralInteresado";
  //import Generals from "../../components/forms/Generals";
  //import Complement from "../../components/forms/VG/Complement";
  //import MotivosAgravio from "../../components/forms/VG/MotivosAgravio";
  import DatosExpediente from "../../components/formsChatET/typePrediction/DI";
  //import Riegos from "../../components/forms/VG/Riesgos";
  import Modal from '../Modal/ModalFormats/ModalBuild.vue';
  import { fr } from '../../firebase';
  import axios from 'axios';
  
  export default {
    name: "DemocraciaIntrapartidista",
    metaInfo: {
      title: "Electoral Tech & Legan | Democracia intrapartidista",
      description:
        "Descripción",
    },
    components: {
      ScrollupSection,
      HeaderSection,
      BreadcrumbSection,
      FooterSection,
      ModalSearchSection,
      ModalMenuSection,
      ModalRegistro,
      ModalTerms,
      HorizontalStepper,
      ModalCapcha,
      Modal
    },
    mounted() {
      analytics.logEvent("formatoComenzado", { name: "Democracia intrapartidista" });
      window.$("#modalTerminos").modal("show");
      this.setTipoFormato(1);
      // window.$("#ModalCaptcha").modal("show");
    },
    data() {
      return {
        showModal: false,
        modalMessage: '',
        titulo: "Democracia intrapartidista",
        tituloFormato: "Violencia politica en razón de género",
        tituloArchivo: "formato-politica-en-razon-genero.pdf",
        descripcion:"",
        urlProd:
          "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/generarVPRG",
        urlLocal:
          "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/generarVPRG",
        demoSteps: [
          {
            icon: "person",
            name: "first",
            title: "Datos del interesado",
            // subtitle: "Subtitle sample",
            component: GeneralFormInteresado,
            completed: false,
          },
          {
            icon: "list",
            name: "second",
            title: "Datos del expediente",
            // subtitle: "Subtitle sample",
            component: DatosExpediente,
            completed: false,
          },
        ],
      };
    },
    computed: {
      url() {
        return process.env.NODE_ENV == "development"
          ? this.urlLocal
          : this.urlProd;
      },
    },
    methods: {
      ...mapMutations('generals', ['setTipoFormato']),
      ...mapActions('contenido', ['oneHotEncodingDI'] ),
      completeStep(payload) {
        this.demoSteps.forEach((step) => {
          if (step.name === payload.name) {
            step.completed = true;
          }
        });
      },
      errorMessage(error) {
        this.$swal({
          icon: "error",
          title: error,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showCancelButton: true,
        });
      },
      // Executed when @active-step event is triggered
      isStepActive(payload) {
        this.demoSteps.forEach((step) => {
          if (step.name === payload.name) {
            if (step.completed === true) {
              step.completed = false;
            }
          }
        });
      },

      async checkSubscriptionStatus() {
        try {
          const userId = auth.currentUser.uid;

          const snapshot = await fr.collection('suscriptoresPremiumPlus')
                                    .where('idUsuario', '==', userId)
                                    .where('estado', '==', 'activo')
                                    .get();

          if (!snapshot.empty) {
            const doc = snapshot.docs[0];
            console.log('Usuario encontrado:', doc.data());
            this.procesarPrediccion();
          } else {
            console.log('El usuario no tiene una suscripción activa.');
            this.modalHeadMessage = 'Se requiere suscripción a Premium+';
            this.modalMessage = 'Ve a "Ver Perfil" para gestionar tu suscripción';
            this.showModal = true;
          }
        } catch (error) {
          console.error('Error en checkSubscriptionStatus:', error);
          throw error; // Lanza el error para que sea capturado por el método alert()
        }
      },

      async procesarPrediccion() {
        try {
          this.$swal({
            icon: "info",
            title: "Realizando predicción",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            timerProgressBar: true,
            showCancelButton: true,
            didOpen: () => this.$swal.showLoading(),
          });

          const datosExp = {
            'TipoInpugnacion': this.$refs.pasosDI.$children[1].form.tipoImpugnacion,
            'Ponente': this.$refs.pasosDI.$children[1].form.ponente,
            'Modalidades': this.$refs.pasosDI.$children[1].form.modalidades
          };

          const instanciaCodificada = await this.oneHotEncodingDI(datosExp);

          const fechaActual = new Date();

          const datosPPRV = {
            appMaterno: this.$refs.pasosDI.$children[0].form.appMat,
            appPaterno: this.$refs.pasosDI.$children[0].form.appPat,
            codigoPostal: this.$refs.pasosDI.$children[0].form.cp,
            correoElectronico: this.$refs.pasosDI.$children[0].form.correo,
            fechaRegistro: fechaActual,
            idUsuario: auth.currentUser.uid,
            modalidades: this.$refs.pasosDI.$children[1].form.modalidades,
            nombre: this.$refs.pasosDI.$children[0].form.nombres,
            ponente: this.$refs.pasosDI.$children[1].form.ponente,
            telefono: this.$refs.pasosDI.$children[0].form.telefono,
            tema: "DI",
            tipoImpugnacion: this.$refs.pasosDI.$children[1].form.tipoImpugnacion,
          };
          
          const pprvDocRef = await fr.collection('predicciones').doc('DI').collection('PPRV').add(datosPPRV);
          const pprvDocId = pprvDocRef.id;

          const response = await axios.post("https://us-central1-electoraltech-d9cec.cloudfunctions.net/predicDI",{
              instances: [instanciaCodificada],
            }
          )

          const datosResultados = {
            pprvDocId: pprvDocId,
            diasTranscurridos: Math.round(response.data[2]),
            fechaRegistro: fechaActual,
            idUsuario: auth.currentUser.uid,
            modalidades: this.$refs.pasosDI.$children[1].form.modalidades,
            ponente: this.$refs.pasosDI.$children[1].form.ponente,
            sentido: response.data[0],
            tema: "DI",
            tipoImpugnacion: this.$refs.pasosDI.$children[1].form.tipoImpugnacion,
            votacion: response.data[1],
          };

          
          await fr.collection('predicciones').doc('DI').collection('resultados').add(datosResultados);


          this.$swal({
            icon: "success",
            title: "Predicción de expediente con temática de Democracia Intrapartidista",
            text: `Para ver tu predicción, debes ir a tu perfil`,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              console.log('Usuario confirmó');
              this.$router.push({ name: "perfil" });
            } else if (result.isDenied) {
              console.log('Usuario no confirmó');
            }
          });

        } catch (error) {
          console.warn("Error al procesar la predicción:", error);
          this.errorMessage("Revise su conexión a internet y pruebe más tarde");
        }
      },

      async alert() {
        // window.$("#ModalCaptcha").modal("show");}
        if ( auth.currentUser != null ) {
          try {
            await this.checkSubscriptionStatus();

            

          } catch (error) {
            console.error('Error en alert:', error);
            this.errorMessage('Revise su conexión a internet y pruebe más tarde');
          }

        } else {
          const result = await this.$swal({
            icon: "info",
            title: "Registrate o Inicia sesión!",
            text: "Crea tu cuenta o inicia sesión para ver tu predicción en tu perfil",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showCancelButton: true,
            confirmButtonText: 'Crear cuenta ahora',
          });
          if (result.isConfirmed) {
            window.$("#registro").modal('show');
          } else if (result.isDenied) {
            this.$router.push({ name: "inicio" });
            console.log('no confirmo')
          }
        }
      },
      
    },
  };
  </script>
  
  <style>
  .stepper-box .content {
    overflow: visible !important;
  }
  </style>